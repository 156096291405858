<template>
  <div class="is-inline-block">
    <a class="is-link is-text-underlined" @click="openModal">
      <label class="is-clickable">{{ label }}</label>
      <b-icon
        icon-pack="fa"
        icon="paperclip"
        size="is-small"
        type="is-blue"
        class="mr-2 ml-2"
      ></b-icon>
    </a>

    <b-modal v-model="isModalOpen" :can-cancel="canCancel">
      <div class="modal-card">
        <vue-pdf-app
          :pdf="blobURL"
          :config="pdfViewConfig"
          page-scale="auto"
        ></vue-pdf-app>

        <div class="is-flex mt-4" v-if="!canCancel">
          <b-button
            id="statementReaderButtonAccept"
            type="is-blue"
            class="has-text-weight-medium"
            @click="accept"
            >{{ $t("af:statement_reader.button.accept") }}
          </b-button>
        </div>

        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "StatementReaderModal",
  components: {
    VuePdfApp,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
    isPublic: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
      blobURL: null,
      currentPage: 0,
      pageCount: 0,
      pdfViewConfig: {
        sidebar: false,
        secondaryToolbar: false,
        toolbar: {
          toolbarViewerLeft: false,
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: true,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: false,
          },
        },
        errorWrapper: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      fileGetter: "drawer/getFile",
    }),
  },
  async created() {
    try {
      if (this.isPublic) {
        await this.downloadPublic(this.documentId);
      } else {
        await this.download(this.documentId);
      }

      const blob = this.fileGetter(this.documentId);
      this.blobURL = URL.createObjectURL(blob);
    } catch (e) {
      console.warn(
        "Couldn't load Statement with ID: " + this.documentId + " !"
      );
    }
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
      downloadPublic: "drawer/downloadPublic",
    }),
    openModal() {
      this.isModalOpen = true;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    accept() {
      this.$emit("accept");
      this.cancelModal();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.pdf-app.dark {
  --pdf-app-background-color: #525659;
}

.modal-card {
  background: white;
  border: 1px solid $grey-lightest;
  border-radius: 24px;
  position: relative;
  height: 79vh;
  width: 100%;
  padding: 2rem;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.pdf-app #toolbarViewerMiddle {
  left: 0;
  transform: none;
}

.pdf-app .pdfViewer .page {
  border: none !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 900px) {
  .pdf-app #toolbarViewerMiddle {
    display: block;
  }

  .pdf-app #outerContainer .hiddenMediumView {
    display: block;
  }
}
</style>
